import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const RewardsApplicationConfigSchema = object({
    rewardsPopUpWidgetWrapperPlaceholder: string(),
    rewardsPopUpRootWidgetPlaceholder: string(),
    rewardsVerifyAccountModalWidgetPlaceholder: string(),
    rewardsJoinNowModalWidgetPlaceholder: string(),
    rewardsWaysToEarnPageWidgetPlaceholder: string(),
    rewardsWaysToEarnPageWidgetModalPlaceholder: string(),
    rewardsWaysToSpendPageWidgetPlaceholder: string(),
    rewardsWaysToSpendPageWidgetModalPlaceholder: string(),
    rewardsCustomerRewardsModalPlaceholder: string(),
    rewardsVipTiersPageWidgetPlaceholder: string(),
    rewardsReferralProgramPageWidgetPlaceholder: string(),
    rewardsEarnPointsProductPagePlaceholder: string(),
    rewardsEmptyStatePageWidgetPlaceholder: string(),
});

export type RewardsApplicationConfigInterface = Infer<
    typeof RewardsApplicationConfigSchema
>;

export const RewardsApplicationConfigToken = Symbol(
    "RewardsApplicationConfigToken"
) as InjectionToken<RewardsApplicationConfigInterface>;
