import {
    globalLoggerToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
} from "@/tokens";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { container, inject, instanceCachingFactory, singleton } from "tsyringe";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import { LoggerInterface } from "@interfaces/LoggerInterface";
import { ModuleInterface } from "@interfaces/ModuleInterface";

import ApplyDiscountButtonProvider from "./ApplyDiscountProvider/ApplyDiscountButtonProvider";
import { BaseUrlProvider } from "./BaseUrlProvider/BaseUrlProvider";
import { DesignModeProviderInterface } from "./DesignModeProvider/DesignModeProviderInterface";
import { FromShopifyObjectDesignModeProvider } from "./DesignModeProvider/FromShopifyObjectDesignModeProvider";
import DesignModeQueryParamsProvider from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProvider";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProviderInterface";
import { referrerProviderToken } from "./di_tokens";
import { GraphQLApiClient } from "./GraphQLApiClient/GraphQLApiClient";
import LocationController from "./LocationController/LocationController";
import LocationControllerInterface from "./LocationController/LocationControllerInterface";
import ReferrerProvider, {
    ReferrerProviderInterface,
} from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";
import { StorefrontApiBaseUrlProvider } from "./StorefrontApiBaseUrlProvider/StorefrontApiBaseUrlProvider";
import { URLsBuilder } from "./URLsBuilder/URLsBuilder";

interface CommonModuleExports {
    locationController: LocationControllerInterface;
    referrerProvider: ReferrerProviderInterface;
    applyDiscountProvider: ApplyDiscountButtonProvider;
    designModeProvider: DesignModeProviderInterface;
    designModeQueryParamsProvider: DesignModeQueryParamsProviderInterface;
    baseUrlProvider: BaseUrlProvider;
    graphqlApiClient: GraphQLApiClient | null;
    urlsBuilder: URLsBuilder;
}

@singleton()
export class CommonModule implements ModuleInterface<CommonModuleExports> {
    exports: CommonModuleExports;

    public constructor(
        @inject(globalLoggerToken)
        private readonly globalLogger: LoggerInterface,
        @inject(gwStorefrontAppInfoToken)
        private readonly gwStorefrontAppInfo: GwStorefrontAppInfo,
        @inject(growaveConfigurationToken)
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {
        const commonModuleLogger = this.globalLogger.newLogger("CommonModule");

        const locationController = new LocationController(window.location);

        const shopifyUrlUtils = new ShopifyUrlUtils(
            window.Shopify?.routes?.root || "/"
        );

        container.register(referrerProviderToken, {
            useFactory: instanceCachingFactory(() => {
                return new ReferrerProvider();
            }),
        });

        const designModeProvider = new FromShopifyObjectDesignModeProvider();

        const designModeQueryParamsProvider = new DesignModeQueryParamsProvider(
            designModeProvider,
            locationController
        );

        const baseUrlProvider = new BaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        const storefrontApiBaseUrlProvider = new StorefrontApiBaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        let graphqlApiClient: GraphQLApiClient | null = null;
        if (growaveConfiguration.storefrontApiAccessToken) {
            graphqlApiClient = new GraphQLApiClient(
                storefrontApiBaseUrlProvider.appendToBaseUrl(
                    "/api/2024-07/graphql.json"
                ),
                growaveConfiguration.storefrontApiAccessToken
            );
        }

        const urlsBuilder = new URLsBuilder(
            shopifyUrlUtils,
            locationController
        );

        this.exports = {
            locationController: locationController,
            referrerProvider: container.resolve(referrerProviderToken),
            applyDiscountProvider: new ApplyDiscountButtonProvider(
                this.growaveConfiguration.canApplyDiscountViaButton
            ),
            designModeProvider,
            designModeQueryParamsProvider,
            baseUrlProvider,
            graphqlApiClient,
            urlsBuilder,
        };
    }

    public registerProviders() {
        return {};
    }
}
